* {
  margin: 0;
  font-family: "Poppins", Sans-serif;
  }
  

html {
scroll-snap-type: mandatory y ;
scroll-behavior: smooth;
}
section {
  scroll-snap-align: center;
}
body {
  padding-top: "70px";
}

